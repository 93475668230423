import * as Sentry from '@sentry/vue'
// jquery not essential. should be replaced
// see Todo with $
	'use strict';

	var blocks = [],
		loadQueue = [],
		blockElements,
		navElement,
		blockLinks,
		inlineBlockLinks,
		preLoadedBlockIndex = 0,
		blockPositions = [],
		recentLoadedBlock = { href: '' };

	function Block (element) {
		this.element = element;
		this.loaded = element.getAttribute('data-lazy') === 'false';
		this.autoLoad = !element.hasAttribute('data-manual');
		this.href = element.getAttribute('data-href'); // probably not present. assign on link click before load call
		this.id = element.id;

		element.removeAttribute('data-lazy');

		if (!this.autoLoad) {
			this.element.addEventListener('click', manualQueueLoad.bind(this, this.id));
		}
	}

	Block.prototype.load = function (replaceUrl) {
		var self = this;

		if (recentLoadedBlock.href === self.href) return false;
		recentLoadedBlock = self;

		return new Promise(function (resolve, reject) {
			if (self.loaded) return resolve(self);
			var request = new XMLHttpRequest();

			request.open('GET', self.id, true);
			request.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

			request.onerror = reject;
			request.onload = function () {
				if (request.status >= 200 && request.status < 400) {
					// TODO: request is only supposed to send relevant markup.
					// should be no need to create div and look up target element.
					var target = document.createElement('div');
					target.innerHTML = request.response;
					target = target.querySelector('#' + self.id);

					self.replaceElement(target);
					self.element.dispatchEvent(createCustomEvent('lazyload'));

					self.loaded = true;

					resolve(self);
					setBlockPositions();
					positionCheck();
				}
				else {
					reject();
				}
			};

			request.send();
		});
	};

	function setHref (href) {
        if (href === null) return false;
        if (href === window.location.href) return false;

		window.dispatchEvent(createCustomEvent('replaceState',href));
		return window.history.replaceState(null, '', href);
	}

	Block.prototype.replaceElement = function (newElement) {
		var oldElement = this.element,
			parent = this.element.parentElement;

		this.element = parent.insertBefore(newElement, oldElement);
		parent.removeChild(oldElement);
	};

	Block.prototype.testVisibility = function (fromScroll) {
		var viewportTop = window.pageYOffset,
			viewportBottom = viewportTop + window.innerHeight,
			elementTop = this.element.offsetTop;

		if (elementTop >= viewportTop && elementTop < viewportBottom) {
			return this.load(fromScroll);
		}

		return false;
	};

	function positionCheck () {
		var viewportTop = window.pageYOffset;
		var viewportMiddle = viewportTop + (window.innerHeight/2);
		var arrI = 0;
		var closestBlock = _.find(blockPositions, function(o, i) {
			arrI = i;
			return o.pos >= viewportMiddle;
		});

		//last block
		if (closestBlock === undefined) {

			if(blockPositions[blockPositions.length - 1] == undefined)
			{
				return;
			}

			return setHref(blockPositions[blockPositions.length - 1].href);
		}

		if(blockPositions[arrI - 1] != undefined) {
            if (blockPositions[arrI - 1].loaded === false) {
                var currentBlock = _.find(blockPositions, function (o) {
                    return o.loaded === true;
                });
                return setHref(currentBlock.href);
            }

            setHref(blockPositions[arrI - 1].href);
        }
	};

	function setBlockPositions () {
		blockPositions = [];
		blocks.forEach(function (el, i) {
			var $el = $(el.element);
			var obj = {
				pos: 0,
				href: '',
				loaded: el.loaded,
			};
			obj.pos = $el.offset().top;
			obj.href = el.href;
			blockPositions.push(obj);
		});
	}

	var queueTester = _.throttle(testQueueVisibility, 50);
	function testQueueVisibility (fromScroll) {
		var nextBlock = _.find(loadQueue, {autoLoad: true, loaded: false}),
			visibility;

		fromScroll = !!fromScroll;

		if (!nextBlock) {
			return window.removeEventListener('scroll', queueTester);
		}

		visibility = nextBlock.testVisibility(fromScroll);

		if (visibility) {
			visibility.then(function () {
				_.pull(loadQueue, nextBlock);
				testQueueVisibility(fromScroll);
			});
		}

		return visibility;
	}

	function loadQueueSet (targetIndex) {
		var setBlocks = [],
			setLoads = [];

		_.each(loadQueue, function (block, index) {
			if (index > targetIndex) return false;

			setBlocks.push(block);
			setLoads.push(block.load(false));
		});

		_.pullAll(loadQueue, setBlocks);

		return Promise.all(setLoads);
	}

	function manualQueueLoad (postLoadId) {
		var setBlocks = [],
			setLoads = [];

		_.each(loadQueue, function (block) {
			if (block.loaded) return false;

			setBlocks.push(block);
			setLoads.push(block.load(postLoadId === block.id));
		});

		_.pullAll(loadQueue, setBlocks);

		return Promise.all(setLoads);
	}

	function initBlocks () {

		_.each(document.querySelectorAll('[data-lazy]'), function (element, index) {
			var block = new Block(element);
			blocks.push(block);

			if (block.loaded) {
				preLoadedBlockIndex = index;
			}
			else {
				loadQueue.push(block);
			}
		});
		setBlockPositions();
	}

	function runAssetLoader() {
		loadVueForLazyloadedContent();
		setTimeout(function() {
			setBlockPositions();
		}, 3000);
	}

	function loadVueForLazyloadedContent() {
	    $('body').find('div.vue-container:not(.vue-has-loaded)').each(function() {
            if (!$(this).attr('id')) {
                $(this).attr('id', guidGenerator());
            }

	        if (window.vueLoadedIds.includes($(this).attr('id'))) {
                $(this).addClass('vue-has-loaded');
	            return true;
            }

            $(this).addClass('vue-has-loaded');
            var container = window.createApp({});
            container.component('company-events', window.companyevents);
            container.component('executives', window.executives);
            container.component('beneficial-owners', window.beneficialOwners);
            container.component('bisgraf-select-year', window.bisgrafselect);
            container.component('compare-companies-checkbox', window.comparecompaniescheckbox);
            container.component('plus-indicator', window.plusindicator);
            container.component('strossle', window.strosslecomponent);
            container.mixin(Sentry.createTracingMixins({ trackComponents: true }));
            Sentry.attachErrorHandler(container)
            container.mount('#' + $(this).attr('id'));
        })
    }

    function guidGenerator() {
        var S4 = function() {
            return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
        };
        return 'vue-container-' + (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
    }

	/**
	 * Find make links lazy links
	 * @param  {object} source Element to look for links in
	 */
	function interruptLinks (source) {
		_.each(source.querySelectorAll('[target="lazy"]'), function (element) {
			var  targetId = element.getAttribute('data-target-id'),
				targetIndex = null,
				targetBlock = null;

			_.each(blocks, function (block, index) {
				if (targetId === block.id) {
					targetBlock = block;
					targetIndex = index;
					return false;
				}
			});

			targetBlock.href = targetBlock.href || element.href;

			element.addEventListener('click', function (event) {
				// don't prevent middle click
				if (event.which !== 2)  {
					event.preventDefault();

					var loadUpwards = loadQueue.length && preLoadedBlockIndex > targetIndex,
						load;

					load = loadUpwards ? manualQueueLoad() : loadQueueSet(targetIndex);

					load.then(function () {
						// Replaced jQuery animate with velocity.js to fix IE10-issue
						var scrollElement = targetBlock.element;
                        $(scrollElement).velocity("scroll", {duration: 500, offset: -($('header').height() + 40 + 15)});
					});
				}
			});
		});
	}

	initBlocks();
	interruptLinks(document);

	document.body.addEventListener('lazyload', function (event) {
		interruptLinks(event.target);
		runAssetLoader();
	});
var i = 0;
	window.addEventListener('scroll', function () {
		queueTester();
		positionCheck();
	});
    window.manualCheckIfLazyBlocksShallBeLoaded = function() {
        queueTester();
        positionCheck();
    };
	testQueueVisibility();

