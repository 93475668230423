import { BarChart, PieChart } from 'chartist'
import ChartistTooltip from 'chartist-plugin-tooltips-updated'

	var createChart = {};

	/**
	 * Bar charts
	 */
	createChart.bar = function (element, dataModifier, optionsModifier) {
		var data = getMultiDimensionalData(element);


		var options = {

			referenceValue:0,
			seriesBarDistance: 20,
			chartPadding: {right: 0, left: 0},
			axisX: {
				showGrid: false
			},
			plugins: [
				ChartistTooltip({
					currency: ' ',
					class: 'chartist-tooltip',
					anchorToPoint: true,
					transformTooltipTextFnc: function (data) {
						//split into thousands and add space
						return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
					},
				})
			],


			axisY: {
				scaleMinSpace: 10,
				referenceValue:0,
				showLabel: true,
				onlyInteger: true,
				offset:75,//todo widestNumber(data.series),

				labelInterpolationFnc: function (label, index, labels) {

					var newLabel = '';

					if (index === 0 || index === labels.length - 1 || label === 0) {
						var value = +label,
							suffix = '';

						var $stepp = [' tkr', ' mnkr', ' mdkr'];
						var $type = Math.floor((Math.abs(value).toString().length-1)/3)

						for(var i=0;i<$type;i++)
						{
							value = value / 1000;
						}
						if(value != 0)
						{
							suffix = $stepp[$type];
						}

						value = prettyNumber(value);
						newLabel = value+ suffix;
					}

					return newLabel;
				}
			}

		};

		data = extendChart(data, dataModifier);
		options = extendChart(options, optionsModifier);

		var chart = new BarChart(element, data, options);
		var	gridBaseIndex = null;

		chart.on('draw', function (context) {
			if (context.type === 'bar') {
                if (context.value.y < 0) {
                    context.element.addClass('negative');
                }
                context.element._node.onmouseover = () => {
                    // Find the tooltip div and add the 'tooltip-show' class
                    const tooltip = document.querySelector('.chartist-tooltip');
                    if (tooltip) {
                        tooltip.classList.add('tooltip-show');

                        // Create a new span element for the tooltip value
                        const valueSpan = document.createElement('span');
                        valueSpan.classList.add('chartist-tooltip-value');

                        // Set the text of the span to the value of the bar or point
                        valueSpan.innerHTML = '(tkr)' + "<br>" + context.value.y.toLocaleString();

                        // Append the span to the tooltip div
                        tooltip.appendChild(valueSpan);

                        // Get the bounding box of the bar or point
                        const bbox = context.element._node.getBoundingClientRect();

                        // Set the position of the tooltip to the top of the bar or point
                        tooltip.style.left = (bbox.left + bbox.right) / 2 - tooltip.offsetWidth / 2 + 'px'; // Centres the tooltip horizontally
                        tooltip.style.top = bbox.top - tooltip.offsetHeight - 15 + 'px'; // I needed to raise this by 15px to stop overlap. It should hover over the top of the bar (or segment, if it's a stacked bar chart)
                    }
                };

                // Add mouseout event listener to the bar or point
                context.element._node.onmouseout = () => {
                    // Find the tooltip div and remove the 'tooltip-show' class
                    const tooltip = document.querySelector('.chartist-tooltip');
                    if (tooltip) {
                        tooltip.classList.remove('tooltip-show');

                        // Remove the tooltip value span
                        const valueSpan = tooltip.querySelector('.chartist-tooltip-value');
                        if (valueSpan) {
                            tooltip.removeChild(valueSpan);
                        }

                    }

                }
            }
			else if (context.type === 'grid') {
				if (gridBaseIndex === null) {
					gridBaseIndex = _.indexOf(context.axis.ticks, 0);
				}
				if (gridBaseIndex === context.index) {
					context.element.addClass('base');
				}
			}
		});

		return chart;
	};

	createChart['bar--company-table-chart'] = function (element) {
		var widestValueWidth;
		var chart = createChart.bar(element, null, function (options) {
			options.axisY = options.axisY || {};
			options.axisY.showLabel = true;
			options.axisY.showGrid = true;
			options.plugins = options.plugins || [];
            /*
			options.plugins.push(
				Chartist.plugins.abPager([
					{ pages: 5 },
					{ pages: 10, minWidth: 564 },
					{ pages: 5, minWidth: 640 },
					{ pages: 10, minWidth: 950 }
				])
			);
            */
			return options;
		});

		var parentPager = element.parentElement;
		while (!selectorMatches(parentPager, '[data-pager]') && parentPager.parentElement) {
			parentPager = parentPager.parentElement;
		}

		parentPager.addEventListener('navigate', function (event) {
			var page = event.detail[0];
			if (chart.abPager) {
				chart.abPager.navigate(page);
			}
		});

		return chart;
	};

	/**
	 * Pie charts
	 */
	createChart.pie = function (element, dataModifier, optionsModifier) {
		var data = getSingleDimensionalData(element);
		var	sum = data.sum;
		var	options = {
			chartPadding: 0,
			labelInterpolationFnc: function (value) {
				return Math.round(value / sum * 100) + '%';
			}
		};

		delete data.sum;

		data = extendChart(data, dataModifier);
		options = extendChart(options, optionsModifier);
		/* om minusvärde sätt till 0 annars offsetar det fel (5560482837)*/
        for (var i = 0, len = data.series.length; i < len; i++) {
            data.series[i] = Math.max(data.series[i], 0);
        }
		return new PieChart(element, data, options);
	};

	createChart.donut = function (element) {
		var $element = jQuery(element);
		var dm = $element.attr('data-mode');

		if (dm === '1') {
			// if data-mode == 1 create text container and place percentage values inside
			$element.prepend('<div class="chart-text-container align-left"></div>');
			var $target = $element.find('.chart-text-container');
			var numArr = [];
			var $series = $element.children('.chart__data');
			//add values to array
			$series.map(function() {
				numArr.push(parseInt(this.value));
			});
			//sum values
			var sum = numArr.reduce(function(a, b) { return a + b });
			//for each value append element in target with percentage value.
			numArr.forEach(function (key, i) {
				var val = Math.round(key / sum * 100) + '%';
				$target.append('<span class="val theme-color-' + (i + 1) + '">' + val + '</span>');
			});
		}

		var chart = createChart.pie(element, null, {
			donut: true,
			donutWidth: 10,
			showLabel: false,
		});
		return chart;
	};

	createChart.executive = function (element) {
		var chart = createChart.pie(element, null, {
			donut: true,
			donutWidth: 35,
			showLabel: false
		});
		return chart;
	};


	createChart.groupDonut = function (element) {
		var chart = createChart.pie(element, null, {
			donut: true,
			donutWidth: 10,
			showLabel: false
		});
		return chart;
	};

	/**
	 * Utilities
	 */

	function extendChart (config, modifier) {
		if (typeof modifier === 'object') {
			config = _.defaults(modifier, config);
		}
		else if (typeof modifier === 'function') {
			config = modifier(config);
		}

		return config;
	}

	function getSingleDimensionalData (element) {
		var data = { series: [] };

		_.each(element.querySelectorAll('.chart__data'), function (dataElement) {
			data.series.push(+dataElement.value);
		});

		data.sum = _.sum(data.series);

		return data;
	}

	function getMultiDimensionalData (element) {
		var data = { series: [] };

		_.each(element.querySelectorAll('.chart__label'), function (labelElement) {
			if (!_.has(data, 'labels')) {
				data.labels = [];
			}

			var groupIndex = +labelElement.getAttribute('data-group');
			data.labels[groupIndex] = labelElement.textContent;


		});

		_.each(element.querySelectorAll('.chart__data'), function (dataElement) {
			var groupIndex = +dataElement.getAttribute('data-group'),
				value = +dataElement.value;

			var	meta = dataElement.getAttribute('data-meta');

			if (_.isUndefined(data.series[groupIndex])) {
				data.series[groupIndex] = [];
			}


			data.series[groupIndex].push({value:value,meta:meta});
		});



		// invert grouping.
		// [[x1:y1, x1:y2, x1:y3], [x2:y1, x2:y2, x2:y3]]
		// ->
		// [[x1:y1, x2:y1], [x1:y2, x2:y2], [x1:y3, x2:y3]]
		data.series = _.zip.apply(window, data.series);

		return data;
	}


	/**
	 * Initiate charts
	 * @param {object} source Element to look for charts in
	 */
	function init (source) {
		_.each(source.querySelectorAll('.chart'), function (element) {
			var type = element.getAttribute('data-type');
			createChart[type](element);
		});
	}

	// listen for lazyload and
	document.body.addEventListener('lazyload', function (event) {
		init(event.target);
	});

	// init all charts in body
	init(document);
