(function (pageOverlay, _) {
    'use strict';

    var form = document.querySelector('.search-form'),
        input = form.querySelector('.search-form__input--keyword'),
        formToggle = document.querySelector('.site-header__search-toggle'),
        formOpen = false,
        formIsAttached = form.classList.contains('search-form--attached');

    function toggleForm(open) {
        formOpen = _.isBoolean(open) ? open : !formOpen;

        form.classList[formOpen ? 'add' : 'remove']('search-form--open');

        if (formOpen) {
            input.focus();
        }
    }


    function toggleOverlay(open) {
        if (!formIsAttached) {
            return;
        }

        var action = open ? 'activate' : 'deactivate';
        pageOverlay[action].apply(pageOverlay, Array.prototype.slice.call(arguments, 1));
    }

    if (formIsAttached) {
        formToggle.addEventListener('click', function (event) {
            event.preventDefault();
            toggleForm();
        });
    }

    form.addEventListener('focus', function (event) {
        var target = event.target;
        if (target.tagName === 'INPUT') {
            target.setSelectionRange(0, target.value.length);

            toggleOverlay(true, function () {
                toggleForm(false);
            });
        }
    }, true);

    var localElementPattern = /search-form/;
    form.addEventListener('blur', function () {
        setTimeout(function () {
            if (!localElementPattern.test(document.activeElement.className)) {
                toggleOverlay(false);
            }
        }, 0);
    }, true);


    input.addEventListener('keydown', function (event) {
        // escape
        if (event.which === 27) {
            toggleOverlay(false);
        }
    });

})(window.pageOverlay, window._);

window.toggleSearch = function () {
    window.pageOverlay.callbacks.push(function () {
        $('#js-toggle-mobile-search-input').addClass('tw-hidden');
        $('#company-card_navigation').css('top', $('header').height());
    });
    $('#js-toggle-mobile-search-input').removeClass('tw-hidden');
    $('#company-card_navigation').css('top', $('header').height());
    $('#js-toggle-mobile-search-input input').click().focus();
};
