window.getCompanyInfoFromDataLayer = function() {
    let filter = window.dataLayer.filter(function (x) {
        return typeof Object.keys(x)[0] !== 'undefined' && Object.keys(x)[0] === 'companyInfo'
    });

    if(typeof filter[0].companyInfo !== 'undefined') {
        return filter[0].companyInfo;
    }

    return null;
};

const getProductsWithoutPayTrackingHandler = function (productNameB2B, button) {
    button = (typeof button !== 'undefined') ? button : null;
    let companyType = window.getCompanyInfoFromDataLayer().companyType;
    let companyOrgNr = window.getCompanyInfoFromDataLayer().companyOrgNr;
    let companyName = window.getCompanyInfoFromDataLayer().companyName;
    let SNIcode = window.getCompanyInfoFromDataLayer().SNIcode;
    let SNIindustry = window.getCompanyInfoFromDataLayer().SNIindustry;
    let industryCategory = window.getCompanyInfoFromDataLayer().industryCategory;
    let industrySubCategory = window.getCompanyInfoFromDataLayer().industrySubCategory;

    if(companyOrgNr.length > 0) {
        dataLayer.push({
            event: "selectAsset", // triggered on CtA initiating checkout to EPO on allabolag.se
            select: {
                assetInfo: {
                    productName: productNameB2B,
                    companyName: companyName,
                    companyOrg: companyOrgNr,
                    companyType: companyType,
                    SNIcode: SNIcode,
                    SNIindustry: SNIindustry,
                    industryCategory: industryCategory,
                    industrySubCategory: industrySubCategory
                }
            }
        });
    } else {
        dataLayer.push({
            event: "selectAsset", // triggered on CtA initiating checkout to EPO on allabolag.se
            select: {
                assetInfo: {
                    productName: productNameB2B
                }
            }
        });
    }
    // make sure collect from gtm script gets enough time to run before submit and redirecting to new page
    if(button !== null) {
        delay(function () {
            button.closest("form").submit();
        }, 50);
    }

};

const delay = function (callback, ms) {
    setTimeout(callback, ms);
};

const productPayTrackingHandler = function (productId, productType, orgNr, price, button) {
        button = (typeof button !== 'undefined') ? button : null;
        if (orgNr.length > 0) {
            dataLayer.push({
                event: "addToCart", // triggered on CtA initiating checkout to EPO on allabolag.se
                ecommerce: {
                    currencyCode: "SEK", // string
                    add: {
                        products: [{
                            name: productType, // string - product name
                            id: productId, // string - product id
                            quantity: 1, // number
                            price: price.toString(),
                            variant: "ALLABOLAG", // string - specify the shop
                            brand: orgNr,
                        }]
                    }
                }
            });
        } else {
            dataLayer.push({
                event: "addToCart", // triggered on CtA initiating checkout to EPO on allabolag.se
                ecommerce: {
                    currencyCode: "SEK", // string
                    add: {
                        products: [{
                            name: productType, // string - product name
                            id: productId, // string - product id
                            quantity: 1, // number
                            price: price.toString(),
                            variant: "ALLABOLAG", // string - specify the shop
                        }]
                    }
                }
            });
        }
        // make sure collect from gtm script gets enough time to run before submit and redirecting to new page
        if(button !== null) {
            delay(function () {
                button.closest("form").submit();
            }, 50);
        }
    };
export { delay, productPayTrackingHandler, getProductsWithoutPayTrackingHandler }
