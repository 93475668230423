function getParameterByName(name, url) {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

if (window.location !== window.top.location && window.top.location.origin === window.location.origin) {
    var intendedUrl = getParameterByName('iu', window.top.location);
    if (intendedUrl !== null && intendedUrl !== '') {
        window.top.location = intendedUrl;
    } else {
        window.top.location = window.top.location.origin;
    }
}
