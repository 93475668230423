var allabolagJS = allabolagJS || {};


allabolagJS.modalClass = "modal-is-open";
allabolagJS.openModal = function (id) {
    var modal = $(id);
    if (modal.length > 0) {
        modal.addClass('open');
        $(document).find('body').addClass(allabolagJS.modalClass);
    }
};



allabolagJS.addBevakning = function (orgnr, callback) {
    $.ajax({
        method: "POST",
        url: "/user/addOnlineBevakning",
        data: {
            "_token": GLOBAL_JS.csrf_token,
            "orgnr": orgnr

        }
    }).done(function (response) {

        if (!response.success) {
            alert(response.errorMsg);
        }
        if (typeof callback === "function") {
            callback(response.success);
        }

    }).fail(function (res) {

        if (res.status == 401) {
            //todo trigger login
            alert('du måste logga in');
        }

        if (typeof callback === "function") {
            callback(false);
        }

    });

};

allabolagJS.removeBevakning = function (orgNr, callback) {
    $.ajax({
        method: "POST",
        url: "/user/removeOnlineBevakning",
        data: {
            "_token": GLOBAL_JS.csrf_token,
            "orgnr": orgNr,

        }
    }).done(function (response) {

        if (typeof callback === "function") {
            callback(true);
        }
    }).fail(function (res) {

        alert('det gick inte att slutföra nu');
        if (typeof callback === "function") {
            callback(false);
        }

    });
};

//adjust font size based on space available
function fixJurnamnLength() {

    //check if div is overflowing and if so then adjust the font size. This is done in two places
    $('.p-name:visible, #bolagsoversikt .company-name').each(function() {
        var headDiv = $(this).closest('div')[0];

        if(headDiv.scrollWidth > headDiv.clientWidth) {

            var divDiffPercent = headDiv.clientWidth / headDiv.scrollWidth;
            var fontSizeCss = $(this).css('font-size');
            var fontSize = parseInt(fontSizeCss,10);
            var fontUnit = fontSizeCss.substring(fontSizeCss.length-2);
            var fontSizeNew = Math.floor(fontSize * divDiffPercent);

            $(this).css('font-size',fontSizeNew + fontUnit);
        }
    });

}


//on load
$(function () {

    //behöver fixa bevakning vettigt
    //and ie
    fixJurnamnLength();
    $('.bevaka-btn').click(function (e) {
        if ($(e.target).closest('.no-action').length == 1) {
            return;
        }

        const clickTarget = $(e.target);
        if (clickTarget.attr("data-stateIsMonitoring") == 'true') {
            allabolagJS.removeBevakning(GLOBAL_JS.orgnr, function (success) {
                if (success) {
                    clickTarget.attr("data-stateIsMonitoring", 'false');
                    var $contents = clickTarget.contents();
                    $contents[$contents.length - 1].nodeValue = 'Bevaka företag';
                }
            });
        } else {
            // add monitor
            allabolagJS.addBevakning(GLOBAL_JS.orgnr, function (success) {
                if (success) {
                    clickTarget.attr("data-stateIsMonitoring", 'true');
                    var $contents = clickTarget.contents();
                    $contents[$contents.length - 1].nodeValue = 'Sluta bevaka';
                }
            });
        }
    });


    $('.user-monitoring span').click(function (e) {

        //todo callback
        $orgnr = $(e.target).closest('a').data('orgnr');
        $target = $(e.target);
        allabolagJS.removeBevakning($orgnr, function (success) {
            $target.closest('li').hide();
        });

    });


    $(".success").show(300).delay(2000).hide(300);

    /*
     open modals
     availible modals
     #signup
     #login
     #forgot-password
     #new-password
     #modalMessage
     */
    if (GLOBAL_JS.showForm) {
        allabolagJS.openModal(GLOBAL_JS.showForm);
    }
});


//collapsible
function Collapse(className,close_prev,default_open){
    this._elements = [];
    this._className = String(className);
    this._previous = Boolean(close_prev)
    this._default = typeof(default_open)==="number" ? default_open: -1
    this.getCurrent
    this.init();
}


Collapse.prototype.collectElementbyClass = function(){
    this._elements = [];
    var allelements = document.getElementsByTagName("div");

    for(var i=0;i<allelements.length;i++){
        var collapse_div = allelements[i];
        if (typeof collapse_div.className === "string" && collapse_div.className === this._className){

            var h3s = collapse_div.getElementsByTagName("button");
            var collapse_body = collapse_div.getElementsByClassName("collapse_body");
            if(h3s.length === 1 && collapse_body.length === 1){
                h3s[0].style.cursor = "pointer";

                if(this._default === this._elements.length){
                    collapse_body[0].style.visibility = "visible";
                    collapse_body[0].style.height = collapse_body[0].scrollHeight+"px"
                }else{
                    collapse_body[0].style.height = "0px";
                    collapse_body[0].style.visibility = "hidden";
                }
                this._elements[this._elements.length] = collapse_div;
            }
        }
    }
}
Collapse.prototype.open = function(elm){
    elm.style.visibility = "visible";
    elm.style.height = elm.scrollHeight + "px"

}
Collapse.prototype.close = function(elm){
    elm.style.height = "0px";
    elm.style.visibility = "hidden";
}
Collapse.prototype.isOpen = function(elm){

    return elm.style.visibility === "visible"
}

Collapse.prototype.getCurrent = function(header){
    var cur ;
    if(window.addEventListener){
        cur = header.parentNode
    }else{
        cur = header.parentElement
    }
    return cur.getElementsByClassName("collapse_body")[0]
}

Collapse.prototype.toggleDisplay = function(header){

    var cur = this.getCurrent(header)
    if(this.isOpen(cur)){
        this.close(cur);
    }else{
        this.open(cur);
    }
    if(this._previous){
        for(var i=0;i<this._elements.length;i++){
            if(this._elements[i] !== (cur.parentNode||cur.parentElement)){
                var collapse_body = this._elements[i].getElementsByClassName("collapse_body");
                collapse_body[0].style.height = "0px";
                collapse_body[0].style.visibility = "hidden";

            }
        }
    }
}

Collapse.prototype.init = function(){
    var instance = this;
    this.collectElementbyClass();
    if(this._elements.length === 0){
        return;
    }

    for(var i=0;i<this._elements.length;i++){
        var h3s = this._elements[i].getElementsByTagName("button");
        if(window.addEventListener){
            h3s[0].addEventListener("click",function(){ instance.toggleDisplay(this);},false);
        }else{
            h3s[0].onclick = function(){instance.toggleDisplay(this);}
        }
    }
}


var myCollapse = new Collapse("collapseDiv",true);


jQuery('.script-div').on('DOMSubtreeModified',function() {
    var headheight = jQuery('.script-div').height();
    if (headheight > 0) {
        jQuery('.page.container').css('margin-top', '300px');
        jQuery('#company-links-sticky').css('top', '269px');

        if(jQuery(window).width()<640) {
            jQuery('.page.container').css('margin-top', '280px');

        }else if(jQuery(window).width()< 800)
        {
            jQuery('#company-links-sticky').css('top', '246px');

        }
    }
});

jQuery(window).resize(function() {
    var headheight = jQuery('.script-div').height();
    if (headheight > 0) {
        jQuery('.page.container').css('margin-top', '300px');
        jQuery('#company-links-sticky').css('top', '269px');

        if(jQuery(window).width()<640) {

            jQuery('.page.container').css('margin-top', '280px');

        }else if(jQuery(window).width()< 800)
        {
            jQuery('#company-links-sticky').css('top', '246px');
        }
    }
});

//load public suppliers
jQuery(document).ready(function(){
    jQuery('div.showing').text('Visar 5 av '+jQuery('.public-sector__row').length);
});
function show50(){
    var i=0;
    var theRows= jQuery('.hide__tr').length;
    var all=jQuery('.public-sector__row').length;
    var showing;
    jQuery('tr.public-sector__row.hide__tr').each(function(){
        if(i<50) {
            jQuery(this).removeClass('hide__tr');
        }
        i++;
        theRows= jQuery('.hide__tr').length;
        showing= (all-theRows);
    });
    if(theRows==0){
        jQuery('button.show__all').hide();
    }
    jQuery('div.showing').text('Visar '+showing+' av '+all);
}

function showAll(){
    var theSize = jQuery('tr.public-sector__row').length;
    jQuery('tr.public-sector__row.hide__tr').each(function(){

        jQuery(this).removeClass('hide__tr');
    });
    jQuery('div.showing').text('Visar '+theSize+' av '+theSize);
}
