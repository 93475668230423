(function (window, createCustomEvent) {
    'use strict';

    var googleInit = false;
    var idCounter = 0;
    var adPositionCounter = 0;
    var gtmId = 79379395;
    var windowResizeTimer = null;

    let adsPositions = {
        mobile: {
            hitlist: {
                0: {
                    id: 'mobpan_seek1',
                    sizes: [[300, 250], [320, 50], [320, 80], [320, 160], [320, 240], [320, 320]]
                },
                1: {
                    id: 'mobpan_seek2',
                    sizes: [[300, 250], [320, 50], [320, 80], [320, 160], [320, 240], [320, 320]]
                },
                2: {
                    id: 'mobpan_seek3',
                    sizes: [[300, 250], [320, 50], [320, 80], [320, 160], [320, 240], [320, 320]]
                },
                3: {
                    id: 'mobpan_seek4',
                    sizes: [[300, 250], [320, 50], [320, 80], [320, 160], [320, 240], [320, 320]]
                },
            },
            company: {
                0: {
                    id: 'mobpan_ftg1',
                    sizes: [[300, 250], [320, 50], [320, 80], [320, 160], [320, 320]]
                },
                1: {
                    id: 'mobpan_ftg2',
                    sizes: [[300, 250], [320, 50], [320, 80], [320, 160], [320, 320]]
                },
                2: {
                    id: 'mobpan_ftg3',
                    sizes: [[300, 250], [320, 50], [320, 80], [320, 160], [320, 320]]
                },
                3: {
                    id: 'mobpan_ftg4',
                    sizes: [[300, 250], [320, 50], [320, 80], [320, 160], [320, 240], [320, 320]]
                }
            },
            companyWithPresentation: {
                0: {
                    id: 'mobpan_ftg2',
                    sizes: [[300, 250], [320, 50], [320, 80], [320, 160], [320, 320]]
                },
                1: {
                    id: 'mobpan_ftg3',
                    sizes: [[300, 250], [320, 50], [320, 80], [320, 160], [320, 320]]
                },
                2: {
                    id: 'mobpan_ftg4',
                    sizes: [[300, 250], [320, 50], [320, 80], [320, 160], [320, 320]]
                }
            },
            start: {
                0: {
                    id: 'MobileStartPage',
                    sizes: [[300, 50], [300, 250], [320, 80], [320, 160], [320, 240], [320, 320]]
                }
            }
        },
        desktop: {
            hitlist: {
                0: {
                    id: 'deskpan_seek1',
                    sizes: [[480, 60], [970, 60], [970, 250], [980, 120], [980, 240]]
                },
                1: {
                    id: 'deskpan_seek2',
                    sizes: [[480, 60], [970, 60], [970, 250], [980, 120], [980, 240]]
                },
                2: {
                    id: 'deskpan_seek3',
                    sizes: [[480, 60], [970, 90], [970, 250], [980, 120], [980, 240]]
                },
                3: {
                    id: 'deskpan_seek4',
                    sizes: [[480, 60], [970, 90], [970, 250], [980, 120], [980, 240]]
                },
            },
            company: {
                0: {
                    id: 'deskpan_ftg1',
                    sizes: [[468, 60], [970, 90], [970, 250], [980, 120], [980, 240]]
                },
                1: {
                    id: 'deskpan_ftg2',
                    sizes: [[468, 60], [970, 90], [970, 250], [980, 120], [980, 240]]
                },
                2: {
                    id: 'deskpan_ftg3',
                    sizes: [[468, 60], [970, 90], [970, 250], [980, 120], [980, 240]]
                },
                3: {
                    id: 'deskpan_ftg4',
                    sizes: [[468, 60], [970, 90], [970, 250], [980, 120], [980, 240]]
                },
            },
            companyWithPresentation: {
                0: {
                    id: 'deskpan_ftg2',
                    sizes: [[468, 60], [970, 90], [970, 250], [980, 120], [980, 240]]
                },
                1: {
                    id: 'deskpan_ftg3',
                    sizes: [[468, 60], [970, 90], [970, 250], [980, 120], [980, 240]]
                },
                2: {
                    id: 'deskpan_ftg4',
                    sizes: [[468, 60], [970, 90], [970, 250], [980, 120], [980, 240]]
                },
            },
            start: {
                0: {
                    id: 'startpage980',
                    sizes: [[728, 90], [970, 90], [980, 120], [980, 240]]
                }
            }
        }
    };

    function loadBanner() {

        var page = getPage();
        var viewportType = getViewportType();
        var adInfo = false;

        //check if google stuff has been initiated
        if (googleInit != true) {
            return;
        }

        //Show company ads if page is not set
        if(page === false){
            page = 'company';
        }

        //check if we are on a known page to place banners (hitlist or company page)
        if (page != false) {
            $('.place-holder-BL:visible').not('.hasAd').each(function () {
                if (page === 'hitlist' || page === 'company' || page === 'search') {
                    adInfo = getAdInfo(page, viewportType, adPositionCounter++ % 4);
                } else if(page === 'companyWithPresentation'){
                    adInfo = getAdInfo(page, viewportType, adPositionCounter++ % 3);
                } else {
                    adInfo = getAdInfo(page, viewportType, 0);
                }

                if (adInfo != false) {
                    let htmlObjId = 'loadBanner_ad_' + idCounter++;
                    $(this).attr('id', htmlObjId);

                    if ((viewportType === 'desktop')) {
                        setGoogleAd(adInfo, htmlObjId);
                    } else if ((viewportType === 'mobile')) {
                        setGoogleAd(adInfo, htmlObjId);
                    }
                    $(this).addClass('hasAd');
                }
            });
        }


        //set any ads that has the class adunit
        $('.adunit:visible').not('.hasAd').each(function () {

            var htmlObjId = $(this).attr('id');
            var adId = false;
            var adSizes = false;

            //use current id, if any, or set an id if none exists
            if (typeof (htmlObjId) === 'undefined') {
                htmlObjId = 'loadBanner_ad_' + idCounter++;
                $(this).attr('id', htmlObjId);
            }

            //get ad id and ad sizes from the html tag
            adId = $(this).data('adunit');
            adSizes = $(this).data('dimensions');

            if ((typeof (adId) !== 'undefined') && (typeof (adSizes) !== 'undefined')) {

                //set info in structure
                adInfo = {
                    id: adId,
                    sizes: adSizes
                };

                //create ad
                setGoogleAd(adInfo, htmlObjId);

                $(this).addClass('hasAd');
            }

        });

        $('.lwad:visible').not('.hasAd').each(function() {
            var adid = $(this).attr('id');

            var keywords = {};

            if (typeof window.adsData !== 'undefined' && window.adsData.industry != null) {
                keywords.industry = window.adsData.industry;
            }

            if (typeof window.adsData !== 'undefined' && window.adsData.turnover != null) {
                keywords.turnover = window.adsData.turnover;
            }

            lwhb.cmd.push(function() {
                lwhb.loadAd({
                    tagId: adid,
                    keywords: keywords,
                });
            });
            $(this).addClass('hasAd');
        });
    }


    //init the Google stuff
    function initGooglePublisherTag(runLoadBanner) {

        //load google js file and return a promise that will resolve when its been loaded (and run)
        var jsPromise = $.getScript("https://www.googletagservices.com/tag/js/gpt.js", function () {

            var pDeferred = $.Deferred();
            if (typeof (googletag) === 'undefined') {
                var attempts = 0;
                var pTimer = setInterval(function () {
                    if (typeof (googletag) !== 'undefined') {
                        window.clearInterval(pTimer);
                        pDeferred.resolve();
                    } else if (attempts >= 100) {
                        window.clearInterval(pTimer);
                        pDeferred.reject('Failed to load gpt.js');
                    }

                    attempts++;
                }, 100);

            } else {
                pDeferred.resolve();
            }

            return pDeferred.promise();
        });

        //when js file is loaded then run some init stuff
        jsPromise.then(function () {

            googletag.cmd.push(function () {
                googletag.pubads().disableInitialLoad();
                googletag.pubads().collapseEmptyDivs(true);
                googletag.enableServices();
            });

            googleInit = true;

            //exec function if so should bedone
            if (runLoadBanner == true) {
                loadBanner();
            }

        });

        return jsPromise;
    }


    window.addEventListener('hitlistpage', function () {
        //lazy-load on hitlist page
        loadBanner();
    });
    window.addEventListener('lazyload', function () {
        //lazy-load on company page
        loadBanner();
    });
    window.addEventListener('resize', function () {
        clearTimeout(windowResizeTimer);
        windowResizeTimer = setTimeout(windowsResizeDone, 100);
    });


    function setGoogleAd(adInfo, htmlTagId) {
        googletag.cmd.push(function () {
            var newSlot = googletag.defineSlot('\/' + gtmId + '\/' + adInfo.id, adInfo.sizes, htmlTagId).addService(googletag.pubads());
            googletag.display(htmlTagId);
            googletag.pubads().collapseEmptyDivs(true);
            if (typeof window.adsData !== 'undefined' && window.adsData.industry != null) {
                googletag.pubads().setTargeting('industry', window.adsData.industry);
            }
            if (typeof window.adsData !== 'undefined' && window.adsData.turnover != null) {
                googletag.pubads().setTargeting('turnover', window.adsData.turnover);
            }
            googletag.pubads().refresh([newSlot]);
        });

    }

    //get ad info based on current page and viewport size (mobil/desktop)
    function getAdInfo(page, viewportType, adPlacementId) {
        adPlacementId = (typeof adPlacementId !== 'undefined') ? adPlacementId : 0;

        var rtn = false;

        if (typeof (adsPositions[viewportType]) != 'undefined') {
            if (typeof (adsPositions[viewportType][page][adPlacementId]) != 'undefined') {
                rtn = adsPositions[viewportType][page][adPlacementId];
            }
        }

        return rtn;
    }


    //get if we should use desktop or mobile
    function getViewportType() {
        return $('.B-element:visible').hasClass('mobile') ? 'mobile' : 'desktop';
    }

    //try and check which page we are on
    function getPage() {

        var rtn = false;

        if (typeof (GLOBAL_JS) != 'undefined') {
            if (typeof (GLOBAL_JS.page) != 'undefined') {
                rtn = GLOBAL_JS.page;
            }
        }

        return rtn;
    }

    function windowsResizeDone() {
        loadBanner();
    }


    //init and et any banners
    initGooglePublisherTag(true);

})(window, window.createCustomEvent);
