var activeClass = "ALL";
// currentHistoricalPersonPage = 2 is second page, first page (1) is fetched with php
var currentHistoricalPersonPage = 2;
var canFetchMoreData = true;
var currentHistoricalPersonSize = 100;

(function (window) {
    $(document).on("click tap", ".select-active-positions",function () {
        $(".executives-historical-content-list-block").addClass("hidden");
        activeClass = $("a",this).attr("class");
        $("." + $.trim(activeClass)).removeClass("hidden");
        $('.select-active-positions a').removeClass('tab-active');
        $('.select-active-positions ' + '.' + activeClass).addClass('tab-active');
        reset();
    });

    function reset(){
        $(".load-more-container").show();
        $(".historical-facts .right").find(".executives-historical-content-list-block:visible").not(":lt(5)").addClass("hidden");

        if($(".historical-facts .right").find(".executives-historical-content-list-block." + activeClass + ":hidden").length<1){
            $(".load-more-container").hide();
        }

        $( ".empty-information" ).remove();

        if($('.historical-facts .right').children(':visible').length == 0) {
            if(GLOBAL_JS.hasPlusMembership) {
                $('.historical-facts .right').append('<div class="empty-information">Inget historiskt innehåll i denna kategori.</div>');
            } else {
                $('.historical-facts .right').append('<div class="empty-information">För att visa allt tillgängligt innehåll behöver du ett "Allabolag plus" abonnemang.</div>');
            }
        }

        //Lazyload block if needed when changing category because of fewer items and thus less height on div to avoid a loader that never finish
        window.manualCheckIfLazyBlocksShallBeLoaded();
    }

    $(document).on("click tap",".select-popup-active-tab",function () {
        $("a",this).closest(".missions-tabs").find(".select-popup-active-tab").toggleClass("active");
        $("a",this).closest(".active-missions").find(".list-tab").addClass("hidden");

        var myClass = $("a",this).attr("class");
        $("a",this).closest(".active-missions").find("." + $.trim(myClass)).removeClass("hidden");
    });

    $(document).on("tap", ".list--personnel__info a", function(e) {
        e.preventDefault();
        e.stopPropagation();
        $(this).off("click");
    });

    $(document).on("click tap", ".historical-facts .load-more", function(){
        $(".historical-facts .right").find(".executives-historical-content-list-block." + activeClass + ":hidden:lt(5)").removeClass("hidden");

        if($(".historical-facts .right").find(".executives-historical-content-list-block." + activeClass + ":hidden").length<1){
            $(".load-more-container").hide();
        }
    });

    $(document).on("click tap", ".person-historical-facts .load-more", function(){
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });

        var sPageURL = window.location.href;
        var sURLVariables = sPageURL.split('/');
        var externalUserId = sURLVariables[5].split('#')[0];

        $.ajax({
            type:'GET',
            url:'/executive-historical-paginator/internalId/' + externalUserId + '/size/' + currentHistoricalPersonSize + '/page/' + currentHistoricalPersonPage,
            data:{},

            success:function(data) {
                if (canFetchMoreData) {
                    currentHistoricalPersonPage++;
                    data = $.parseJSON(data);

                    if (data.last) {
                        $('.load-more-person').hide();
                        canFetchMoreData = false;
                    }

                    var content = data.data;

                    var appendClass = '.person-historical-facts-part';

                    var itemNumber = 0;
                    content.forEach(function (item) {
                        if (item.datum_from != null && item.datum_to != null) {
                            $(appendClass).append('<div class="flex-grid__col page-' + currentHistoricalPersonPage + '-' + itemNumber +'"></div>');
                            var appendClassInside = appendClass + ' .page-' + currentHistoricalPersonPage + '-' + itemNumber;
                            if(item.company.active) {
                                $(appendClassInside).append('<h4><a href="/' + item.company.orgnr + '"></a></h4>');
                                $(appendClassInside + ' h4 a').append(item.company.jurnamn);
                            }else {
                                $(appendClassInside).append('<h4 class="inactive-company"></h4>');
                                $(appendClassInside + ' h4').append(item.company.jurnamn + ' (inaktivt bolag)');
                            }
                            $(appendClassInside).append('<p></p>');
                            var classToAppend = appendClassInside + ' p';
                            $(classToAppend).append(item.company.ua_postort + '<br />');
                            item.assignments.forEach(function (element) {
                                $(classToAppend).append(element.description + '<br />')
                            });
                            $(classToAppend).append('Från ' + (item.datum_to < item.datum_from ? 'okänt' : item.datum_from)  + ' till ' + item.datum_to + '<br />');
                        }
                        itemNumber++;
                    });
                }
            }

        });

    });

})(window);

function executiveHoverIn(){
    var relativeObjectPositionFromViewportTop = ($(this).position().top - $(window).scrollTop());

    if(relativeObjectPositionFromViewportTop < 460) {
        $(this).find('.active-missions').addClass('active-missions-near-page-top');
        $(this).find('.active-missions-bottom-arrow').addClass('move-bottom-arrow-to-top');
    }
}

function executiveHoverOut(){
    $('.active-missions').removeClass('active-missions-near-page-top');
    $('.active-missions-bottom-arrow').removeClass('move-bottom-arrow-to-top');
}

window.loadExecutiveJs = function () {
    $(".historical-facts .right").find(".executives-historical-content-list-block").addClass("hidden");
    $(".historical-facts .right").find(".executives-historical-content-list-block:lt(5)").removeClass("hidden");

    if ($(".historical-facts .right").find(".executives-historical-content-list-block." + activeClass + ":hidden").length < 1) {
        $(".load-more-container").hide();
    }

    $('.list--personnel__info').hover(executiveHoverIn, executiveHoverOut);

};

var hasRunObserver = false;

window.dom_observer = new MutationObserver(function(mutation) {
    //This triggers when mutation occurs e.g. runs when blocks are lazyloaded
    var myEle = document.getElementById("befattningar-historisk");
    if(myEle && !hasRunObserver) {
        hasRunObserver = true;
        window.loadExecutiveJs();
    }
});
var container = document.body;
var config = { attributes: true, childList: true, characterData: true, subtree: true };
window.dom_observer.observe(container, config);



