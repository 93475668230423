(function () {
	'use strict';

	function prettyNumber (number) {
		if (isNaN(+number)) return number;
		else if (Math.abs(number) < 1000) return number.toString();

		var negative = number < 0,
			caret;

		number = Math.abs(number).toString().replace('.', ',');
		caret = number.indexOf(',');
		caret = (caret < 0) ? number.length : caret;

		while (caret > 3) {
			caret -= 3;
			number = number.substring(0, caret) + ' ' + number.substr(caret);
		}

		return negative ? '-' + number : number;
	}

	/**
	 * wierdo solution:
	 * function is needed in client as well as
	 * in node template preprocessor.
	 * therefore export it if it's used by node.
	 */
	if (typeof window !== 'undefined') {
		window.prettyNumber = prettyNumber;
	}
	else if (typeof module !== 'undefined') {
		module.exports = prettyNumber;
	}
})();
