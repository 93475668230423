(function () {
	'use strict';

	// Browser compatibility function for javascript "matches"
	function selectorMatches (el, selector) {
		var p = Element.prototype;
		var f = p.matches || p.webkitMatchesSelector || p.mozMatchesSelector || p.msMatchesSelector || function (s) {
			return [].indexOf.call(document.querySelectorAll(s), this) !== -1;
		};
		return f.call(el, selector);
	}


	window.selectorMatches = selectorMatches;

})();
