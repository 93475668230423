(function (_) {
	'use strict';

	function Overlay (target) {
		var element = document.createElement('div');
		element.className = 'page-overlay';
		target.insertBefore(element, target.firstChild);
        element.innerHTML = '<span class="overlay-close"><img style="width:100%;height:100%;"></span>';
		this.element = element;
		this.visible = false;
		this.callbacks = [];

		this.element.addEventListener('click', function () {
			this.deactivate();
		}.bind(this));
	}

	Overlay.prototype.runCallbacks = function () {
		_.forEach(this.callbacks, function (callback) {
			callback();
		});
	};

	Overlay.prototype.toggle = function (visible) {
		visible = (typeof visible === 'boolean') ? visible : !this.visible;
		this.element.classList[visible ? 'add' : 'remove']('page-overlay--active');
		this.visible = visible;
	};

	Overlay.prototype.activate = function (callback) {
		this.toggle(true);

		if (callback) {
			this.callbacks.push(callback);
		}
	};

	Overlay.prototype.deactivate = function (skipCallback) {
		if (!skipCallback) this.runCallbacks();
		this.toggle(false);
		this.callbacks.splice(0, this.callbacks.length);
	};

	window.pageOverlay = new Overlay(document.body);

})(window._);
