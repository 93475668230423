	'use strict';

	/**
	 * Amount of pages that are visible
	 */
	var amountPagesVisible = null;

	/**
	 * Amount of pages that are available
	 */
	var pagesAvailable = 10;

	/**
	 * Breakpoints
	 */
	var pageSettings = [
		{ pages: 1 },
		{ pages: 2, minWidth: 442 },
		{ pages: 3, minWidth: 564 },
		{ pages: 1, minWidth: 640 },
		{ pages: 2, minWidth: 860 },
		{ pages: 3, minWidth: 950 }
	];

	/**
	 * Set how many pages should be visible
	 * based on viewport width
	 * @param {number} amount Amount of pages that should be visible
	 */
	function setPagesVisible (amount) {
		amountPagesVisible = amount; // make this assume current breakpoint
		Pager.updateAll();
	}

	/**
	 * Adds a breakpoint for pager
	 * @param {string} query Media query to be used with matchMedia
	 * @param {object} settings   Object with settings for breakpoint
	 */
	function addBreakpoint (query, settings) {
		var mediaQuery = window.matchMedia(query);
		mediaQuery.addListener(function (event) {
			if (event.matches) {
				setPagesVisible(settings.pages);
			}
		});

		if (mediaQuery.matches) {
			setPagesVisible(settings.pages);
		}
	}

	/**
	 * Add breakpoints for pager
	 * @return {boolean} If breakpoints were added
	 */
	function addBreakpoints () {
		if (amountPagesVisible !== null) return;
		var settingsLength = pageSettings.length,
			mediaQueryTemplate = _.template(
				'<% if (min) { %>(min-width: ${min}) <% } %>' +
				'<% if (min && max) { %>and <% } %>' +
				'<% if (max) { %>(max-width: ${max})<% } %>'
			);

		_.each(pageSettings, function (setting, index) {
			var minWidth, maxWidth, mediaQuery;

			if (setting.minWidth) {
				minWidth = setting.minWidth / 16 + 'em';
			}

			if (index + 1 < settingsLength) {
				maxWidth = (pageSettings[index + 1].minWidth - 1) / 16 + 'em';
			}

			mediaQuery = mediaQueryTemplate({min: minWidth, max: maxWidth});
			addBreakpoint(mediaQuery, setting);
		});
	}


	/**
	 * ID counter.
	 * will increment on assignment
	 */
	var pagerInstances = [];

	/**
	 * Template for controlling stylesheet
	 * @param  {string} id ID of pager instance
	 * @param  {array} 	currentPages Indices of current pages
	 * @return {string} stylesheet content
	 */
	var styleSheetTemplate = _.template(
		'.${id} .data-pager__page { display:table-cell;position:absolute;visibility:hidden;left:-9999px; }\n' +
		'<% _.forEach(currentPages, function (page) { %>' +
		'.${id} .data-pager__page--${page} { display:table-cell;position:static;visibility:visible;left:0; }\n' +
		'<% }); %>'
	);

	/**
	 * Pager constructor
	 * Add pager control to an Element
	 * @param {object} element DOM element to control
	 */
	function Pager (element) {

		var self = this;

		var $tbody;
		if($tbody = $(element).find('tbody > tr')[0])
		{
			var $nrPage =  ($($tbody).find('td').length-1);
			this.pagesAvailable = Math.min($nrPage, 10);
		}

		this.id = 'data-pager--' + pagerInstances.push(this);
		this.element = element;
		this.styleSheet = document.createElement('style');

		this.currentPages = [];

		this.element.classList.add(this.id);
		this.element.appendChild(this.styleSheet);

		this.element.delegate('click', '.data-pager__button', function (event) {
			self.navigate(this.getAttribute('data-direction') === 'forwards');
		});

		// set breakpoints
		this.element.classList.add('data-pager--active');
		this.element.dataPager = this;

		this.navigate();
	}

	/**
	 * Update all pagers
	 */
	Pager.updateAll = function () {
		_.each(pagerInstances, function (pager) {
			pager.navigate();
		});
	};

	/**
	 * Navigate to next or previous pages
	 * @param  {boolean} forwards If direction is forwards
	 */
	Pager.prototype.navigate = function (forwards) {

		if(amountPagesVisible >= this.pagesAvailable)
		{
			$(this.element).find('.data-pager__button').hide();
		}else
		{
			$(this.element).find('.data-pager__button').show();
		}


		var start = this.currentPages[0] || 0,
			upcomingPages = [],
			autoTrigger = _.isUndefined(forwards);

		start += (autoTrigger) ? 0 : (forwards) ? 1 : -1;
		start = _.clamp(start, 0, this.pagesAvailable - amountPagesVisible);
		upcomingPages = _.range(start, start + amountPagesVisible);

		// same as current. don't do anything
		if (
			this.currentPages[0] === upcomingPages[0] &&
			this.currentPages.length === amountPagesVisible
		) return;

		this.styleSheet.innerHTML = styleSheetTemplate({
			id: this.id,
			currentPages: upcomingPages
		});

		this.currentPages = upcomingPages;
		if (!autoTrigger) {
			this.element.dispatchEvent(createCustomEvent('navigate', this.currentPages));
		}
	};

	/**
	 * Initialize pager
	 * @param {object} source Element look for pager in
	 */
	function init (source) {


		_.each(source.querySelectorAll('[data-pager]'), function (element) {
			new Pager(element);
		});

		addBreakpoints();


	}

	// listen for lazyload and init all lazily loaded pagers
	document.body.addEventListener('lazyload', function (event) {
		init(event.target);
	});

	// init all pagers in body
	init(document);
