import '../../../node_modules/lodash/lodash.js'
import '../../../node_modules/velocity-animate/velocity.js'
import './selector-matches.js'
//import './chartist-plugin-pager.js'
import './page-overlay.js'
import { create } from './create-custom-event.js'
window.createCustomEvent = create
import './pretty-number.js'
import './delegate.js'
import './chart.js'
import './data-pager.js'
import './accordion.js'
import './modal.js'
import './custom/search-form.js'
import './custom/allabolag.js'
import './custom/lazy.js'
import './custom/payment.js'
import './custom/intendedUrl.js'
import './custom/GApageTrack.js'
import './custom/bannerLoader.js'
import './custom/bannerResize.js'
import './custom/iframeBreaker.js'
import './custom/product-purchase.js'
import './custom/executives.js'
import './custom/plusAd.js'
import './custom/searchPlaceholder.js'
import {
    delay,
    getProductsWithoutPayTrackingHandler,
    productPayTrackingHandler
} from './custom/productPayTrackingHandler.js'
window.getProductsWithoutPayTrackingHandler = getProductsWithoutPayTrackingHandler;
window.productPayTrackingHandler = productPayTrackingHandler;
window.delay = delay
import { plusButtonEcommerceTrackingHandler } from './custom/plusButtonTrackingHandler.js'
window.plusButtonEcommerceTrackingHandler = plusButtonEcommerceTrackingHandler
