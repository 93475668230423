const create = (name, data) => {
    var event

    if (typeof window.CustomEvent === 'function') {
        event = new CustomEvent(name, { bubbles: true, detail: data })
    } else {
        event = document.createEvent('CustomEvent')
        event.initCustomEvent(name, true, true, data)
    }

    return event
}
export { create }
