(function (window) {
    $(document).on("click", ".plus-modal-open", function () {
        $('#' + $(this).data('modal-id')).css('display', 'block');
    });

    $(document).on("click", ".plus-modal-close", function () {
        $(this).parents('div.plus-modal').css('display', 'none');
    });

    window.onclick = function(event) {
        if ($(event.target).hasClass('plus-modal')) {
            $(event.target).css('display', 'none');
        }
    };

    document.onkeydown = function(evt) {
        if (evt.code === "Escape") {
            $('.plus-modal').css('display', 'none');
        }
    };
})(window);
