    const plusButtonEcommerceTrackingHandler = function () {
        dataLayer.push({
            event: "addToCart", // triggered on CtA initiating checkout to EPO on allabolag.se
            ecommerce: {
                currencyCode: "SEK", // string
                add: {
                    products: [{
                        name: "Allabolag Plus", // string - product name
                        id: "201", // string - product id
                        quantity: 1, // number
                        variant: "ALLABOLAG", // string - specify the shop
                    }]
                }
            }
        });
    }
export { plusButtonEcommerceTrackingHandler };
