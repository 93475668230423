(function (window, document) {
	'use strict';

	var modalClass = "modal-is-open";
	
	function init (source){
		
		source.on('click','[data-modal]', function (evt) {
			
			evt.preventDefault();
			closeAll();
			openModal($(this).data('modal'));
			
		}).on('click', '.modal', function (evt) {
            evt.preventDefault();
            closeAll();
        }).on('click', '.modal div', function (evt) {
            evt.preventDefault();
            evt.stopPropagation();
        });
	
		function openModal(id) {
			var modal = $(id);
			if (modal.length > 0) {
				modal.addClass('open');
				source.find('body').addClass(modalClass);
			}
		}
	
		function closeAll() {
			source.find('.modal.open').removeClass('open');
			source.find('body').removeClass(modalClass);
		}

        source.keyup(function(e) {
            if (e.keyCode == 27) {
            	closeAll();
            }
        });

	}

	init($(document));

})(window, document);
