(function (window, $) {
    'use strict';

    function getCurrentURL() {
        //remove current iu //
        return encodeURIComponent(location.href.split('?iu')[0]);
    }

    function init() {
        $('.addIntendedURL').on('click', function (e) {

            var goto = e.target.href;
            // vissa element är wrappade med a href
            if (!$(e.target).hasClass('addIntendedURL')) {
                goto = $(e.target).closest('.addIntendedURL')[0].href;
            }

            e.preventDefault();
            window.location = goto + "?iu=" + getCurrentURL();
        });
    }

    window.addEventListener('lazyload', function () {
        init();
    });

    init();
})(window, window.$);

