(function (window, document) {
    'use strict';

    var mobileBreakpoint = window.matchMedia('(max-width: 767px)');
    var desktopBreakpoint = window.matchMedia('(min-width: 768px)');

    function toggleAccordion(accordionHead, accordionBody, brakepoint, targetElms) {
        if (brakepoint.matches) {
            accordionHead.off().on('click', function (e) {
                accordion(jQuery(this), targetElms);
            });
            if (window.location.href.indexOf("xv") === -1 &&
                window.location.href.indexOf("xl") === -1 &&
                window.location.href.indexOf("xo") === -1 &&
                window.location.href.indexOf("xe") === -1 &&
                window.location.href.indexOf("xb") === -1 &&
                window.location.href.indexOf("xs") === -1) {
                accordionBody.addClass('display-none');
            }

        } else {
            accordionHead.off();
            accordionBody.removeClass('display-none').removeAttr('style');
            accordionHead.children().removeClass('open');
        }
    };


    function accordion(elm, targetElms) {

        if (elm.hasClass('mobile-only')) {
            elm.parent().find(targetElms).first().slideToggle(250);
        } else {
            elm.parent().find(targetElms).slideToggle(250);
        }

        elm.children().toggleClass('open');
    }

    //lazy loading
    function init(source) {
        //mobile
        var accordionHead = source.find('.accordion-head');
        var accordionBody = source.find('.accordion-body');

        mobileBreakpoint.addListener(function (event) {
            toggleAccordion(accordionHead, accordionBody, mobileBreakpoint, '.accordion-body');
        });

        toggleAccordion(accordionHead, accordionBody, mobileBreakpoint, '.accordion-body');


        //desktop
        var accordionHeadDesktop = source.find('.accordion-head-desktop');
        var accordionBodyDesktop = source.find('.accordion-body-desktop');

        desktopBreakpoint.addListener(function (event) {
            toggleAccordion(accordionHeadDesktop, accordionBodyDesktop, desktopBreakpoint, '.accordion-body-desktop');
        });

        toggleAccordion(accordionHeadDesktop, accordionBodyDesktop, desktopBreakpoint, '.accordion-body-desktop');
    }

    // listen for lazyload and
    document.body.addEventListener('lazyload', function (event) {
        init($(event.target));
    });

    // init all accordions in body
    init($(document));

})(window, document);
