(function (window) {
    'use strict';

    var viewPort = null;

    var bannerCssClass = '.info-area__container.info-area__container--large';
    var bannerDesktopSize = 980;

    if($(bannerCssClass).hasClass('jobb')){
        bannerDesktopSize = 1320;
    }



    function isMobilSize() {

        //todo last value//
        if (window.matchMedia('(min-width: 752px)').matches) {
            return false;
        }
        return true;

    }


    $(window).resize(function () {

        checkIfNewViewPort();
        handelBannerSize();


    });

    function checkIfNewViewPort() {

        var currentVP = isMobilSize() == true ? 'mobile' : 'desktop';
        if (viewPort != currentVP) {
            viewPort = currentVP;
            newVp();
        }

    }

    function newVp() {
        if (viewPort == 'mobile') {
            resetBannerSize();
        }
    }


    function resetBannerSize() {
        var count = $(bannerCssClass).length;
        for (var i = 0; i < count; i++) {
            var el = $(bannerCssClass)[i];
            $(el).addClass('banner-mobile');
            $(el).css({'transformOrigin': "0% 0%"});
            $(el).css({transform: 'none'});

        }
    }
    function handelBannerSize() {

        if (isMobilSize()) {
            return;
        }

        var $el = $(bannerCssClass)[0];

        if ($($el).width() < bannerDesktopSize) {
            changeBannerSize($('.page__main').width());
        } else {
            //only once??
            changeBannerSize(bannerDesktopSize);
        }

    }

    function changeBannerSize($divWidth) {

        var $scale = Math.max($divWidth / bannerDesktopSize, 0.78);

        var count = $(bannerCssClass).length;
        for (var i = 0; i < count; i++) {
            var el = $(bannerCssClass)[i];
            $(el).css({'transformOrigin': "0% 0%"});
            $(el).css({transform: 'scale(' + $scale + ')'});

            // todo addMargin(el);

        }

    }


    window.addEventListener('hitlistpage', function () {
        handelBannerSize();
    });
    window.addEventListener('lazyload', function () {
        handelBannerSize();
    });

    handelBannerSize();


})(window);

