(function (window) {
    'use strict';

    //store all in array?
    var $lastURl = null;

    function sendPageEvent($newUrl)
    {
        if($newUrl == null)
        {
            $newUrl = location.pathname;
        }

        if($lastURl == $newUrl)
        {
            return;
        }
        $lastURl = $newUrl;

        dataLayer.push({
            event:'lazyEvent',
            eventValue:$newUrl
        });


    }



    window.addEventListener('hitlistpage', function (event) {
        sendPageEvent(location.pathname+'/page/'+event.detail.page);
    });

    window.addEventListener('replaceState', function (event) {

        var baseURL = location.protocol + "//" + location.host;
        var path = event.detail.replace(baseURL, "");
        sendPageEvent(path);


    });


    window.addEventListener('trackerEvent', function (event) {

        dataLayer.push({
            event:event.detail.trackerData.eventType,
            eventCategory:event.detail.trackerData.eventCategory,
            eventAction:event.detail.trackerData.eventAction,
            eventLabel:event.detail.trackerData.eventLabel
            //eventValue
        });

    });


    //backup lösning var tracker = ga.getAll()[0];


})(window);
