(function (window) {
	'use strict';

	function delegate (eventType, selector, func) {
		this.addEventListener(eventType, function (event) {
			var subject = event.target,
				match = false;

			while (!(match = selectorMatches(subject, selector))) {
				if (subject === this) break;
				subject = subject.parentElement;
			}

			if (match) {
				func.apply(subject, arguments);
			}
		});
	}

	window.Element.prototype.delegate = delegate;

})(window);
