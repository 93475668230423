(function (window, $) {
    'use strict';
    var loadedPaymentBox = [];

    function isBoxLoaded($paymentBox) {
        if (loadedPaymentBox.indexOf($paymentBox) === -1) {
            loadedPaymentBox.push($paymentBox);
            return false;
        }
        return true;
    }

    function PaymentBox(element) {

        if (isBoxLoaded($(element).data('paymentbox'))) {
            return;
        }

        var self = this;
        this.form = $(element).find('form');

        this.holderBox = $(element).closest('div.payments-container');
        this.maxHeight = 1;
        this.trashHold = 30;

        this.listOfContents = $(element).find('.list-of-contents');


        /////////////////////visual stuff (height)/////////////////////////////
        this.readMoreBox = $(element).find('ul.more-togle')[0];

        this.readMoreBTN = $(element).find('.read-more');
        this.expendedHeight = $(this.readMoreBox).height();

        function toogleInfoText() {
            if ($(self.listOfContents).hasClass('collapsed')) {
                $(self.listOfContents).removeClass('collapsed');
                $(self.readMoreBox).animate({height: self.expendedHeight}, 300, function () {
                    $(self.readMoreBox).css('height', 'auto');
                    self.expendedHeight = $(self.readMoreBox).height();
                    $(self.readMoreBTN).html('DÖLJ');

                });
            } else {
                $(self.listOfContents).addClass('collapsed');

                $(self.readMoreBox).animate({height: self.maxHeight}, 300, function () {
                    $(self.readMoreBTN).html('INNEHÅLL');
                });

            }
        }


        if ($(this.readMoreBox).height() > (this.maxHeight + this.trashHold)) {
            $(self.readMoreBox).css('height', self.maxHeight + 'px');
            $(this.listOfContents).addClass('collapsible');
            $(this.listOfContents).addClass('collapsed');

            if (!$(self.holderBox).hasClass('collapsible')) {
                $(self.holderBox).addClass('collapsible');
            }
        } else {
            $(this.readMoreBox).unwrap();
        }

        this.readMoreBTN.click(function (event) {
            event.preventDefault();
            toogleInfoText();
        });
        /////////////////////end visual stuff (height)//////////////////////////////
    }

    function init(source) {
        var boxes = source.querySelectorAll('.payment-box');
        _.each(boxes, function (box) {
            new PaymentBox(box);
        });
    }

    window.addEventListener('lazyload', function () {
        init(document);
    });

    init(document);
})(window, window.$);
