document.addEventListener('DOMContentLoaded', function() {
    var resizeWindowTimer;

    //run setup code
    runProductPurchaseCode(1);

    function runProductPurchaseCode(startMode){
        var visibleContainer = $('.company-purchase-products__container:visible');

        //clear any set height
        visibleContainer.height('');
        $('.company-purchase-products__product').height('');

        var doSetHeight = true;
        var bodyWidth = $('body').width();

        if (bodyWidth < 930) {
            doSetHeight = false;
        }

        visibleContainer.data('doSetHeight', doSetHeight);

        if (doSetHeight == true) {
            //set current height of container
            $('.company-purchase-products__container:visible').height($('.company-purchase-products__container:visible').height() + 'px');
        }

        if (startMode == 1) {
            $('.company-purchase-products__product').hover(
                function() {
                    var h = $(this).height() + 10;

                    doSetHeightHover = $('.company-purchase-products__container:visible').data('doSetHeight');

                    if (!$(this).hasClass('company-purchase-products__product__selected')) {

                        $('.company-purchase-products__product').removeClass('company-purchase-products__product__selected');
                        $('.company-purchase-products__product').height('');

                        $(this).addClass('company-purchase-products__product__selected');

                        if (doSetHeightHover == true) {
                            $(this).height(h + 'px');
                        }

                    }
                }, function() {}
            );
        }

        //set first as selected, if none is selected
        if ($('.company-purchase-products__product__selected:visible').length == 0) {
            $('.company-purchase-products__product').removeClass('company-purchase-products__product__selected');
            $('.company-purchase-products__product:visible:first-child').mouseenter();
        }
    }

    //on resize do setup again
    window.onresize = function() {
        clearTimeout(resizeWindowTimer);
        resizeWindowTimer = setTimeout(runProductPurchaseCode, 100);
    };

}, false);
